import { redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getJwt, getLogin } from '../api'
import { loginUrl } from '../constants'
import { type TrinityRoles } from '../types'
import { parseFormData } from './objects'

/**
 *
 * @param loginPath url to redirect to if not logged in - defaults to Workflow
 * @returns user object
 */
export async function getUser(loginPath?: string) {
  const token = Cookies.get('jwt') ?? sessionStorage.getItem('jwt')

  if (token) {
    sessionStorage.setItem('jwt', token)
    const user = await getLogin()
    return user
  } else {
    console.error('No JWT found')
    throw redirect(loginPath ?? loginUrl)
  }
}

/**
 *
 * @param role 'admin' | 'supplier' | 'dealer'
 * @param loginPath url to redirect to if not logged in - defaults to Workflow
 * @returns user object
 */
export async function checkAuth(role: TrinityRoles, loginPath?: string) {
  try {
    const user = await getUser(loginPath)
    if (user.roles[role]) {
      return user
    } else {
      console.error(`Unauthorized Access - Not a ${role}`)
      sessionStorage.removeItem('jwt')
      Cookies.remove('jwt')
      throw redirect(loginPath ?? loginUrl)
    }
  } catch (error) {
    console.error('Unauthorized Access - Not logged in')
    sessionStorage.removeItem('jwt')
    Cookies.remove('jwt')
    throw redirect(loginPath ?? loginUrl)
  }
}

/**
 *
 * @param loginPath url to redirect to if not logged in - defaults to Workflow
 * @returns redirect to login page
 */
export function logout(loginPath?: string) {
  sessionStorage.removeItem('jwt')
  Cookies.remove('jwt')
  throw redirect(loginPath ?? loginUrl)
}

/**
 *
 * @param request request object from form submission
 * @param role 'admin' | 'supplier' | 'dealer'
 * @param loginPath url to redirect to if not logged in - defaults to Workflow
 * @returns object with properties: error: boolean, data: string containing error message
 */
export async function login(request: Request, role: TrinityRoles, loginPath?: string) {
  const params = await parseFormData(request)
  const response = await getJwt(params)
  if (response.error) {
    return response
  } else {
    const user = await getUser(loginPath)
    if (user.roles[role]) throw redirect('/')
    return { error: true, data: 'User is not authorized to access this application.' }
  }
}
