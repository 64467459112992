import { AxiosError } from 'axios'
import { z } from 'zod'
import Cookies from 'js-cookie'
import { API, handleError, handleZodError } from './api'
import { jwtSchema, loginSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getLogin() {
  try {
    const response = await API.get('login')
    const token = sessionStorage.getItem('jwt')
    const login = loginSchema.parse({ ...response.data, token })

    return login
  } catch (error) {
    return handleError(error, 'Login')
  }
}

export async function getJwt(rawParams: Record<string, unknown>) {
  try {
    const params = getJwtParamsSchema.parse(rawParams)
    const response = await API.post('login_token', params)
    const { authToken } = jwtSchema.parse(response.data)
    Cookies.set('jwt', authToken)
    sessionStorage.removeItem('jwt')
    sessionStorage.setItem('jwt', authToken)

    return { error: false, data: authToken }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 401) {
      return { error: true, data: String(error.response.data.error) }
    } else {
      return handleError(error, 'JWT')
    }
  }
}

export async function updateSupplier(rawParams: Record<string, unknown>) {
  try {
    const params = updateSupplierSchema.safeParse(rawParams)

    if (params.success) {
      await API.post(`logins/${params.data.id}/update_supplier`, params.data)
      return { errors: null }
    } else {
      const errors = handleZodError(params.error)
      return { errors }
    }
  } catch (error) {
    return handleError(error, 'Update Supplier')
  }
}

//* ---------------- PARAMS ---------------- *//
const getJwtParamsSchema = z.object({
  username: z.string(),
  password: z.string(),
})

const updateSupplierSchema = z
  .object({
    id: z.coerce.number(),
    name: z.string().min(1).max(50),
    email: z.string().email(),
    units: z.union([z.literal('imperial'), z.literal('metric')]),
  })
  .transform(({ name, email, units, ...data }) => ({
    ...data,
    contact_name: name,
    supplier_email: email,
    measurement_units: units,
  }))

//* ---------------- HELPERS ---------------- *//
