import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { GarmentStatus } from '../../constants'

//* ---------------- BASE ---------------- *//

//* ---------------- SCHEMAS ---------------- *//
export const orderStatusSchema = z
  .object({
    code: z.string(),
    description: z.enum(GarmentStatus.VALID),
    name: z.string().nullable(),
  })
  .transform(objectToCamel)

export const delayStatusSchema = z
  .object({
    code: z.string(),
    description: z.string(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type OrderStatusType = z.infer<typeof orderStatusSchema>
    type DelayStatusType = z.infer<typeof delayStatusSchema>
  }
}
