import { z } from 'zod'
import { API, handleError } from './api'
import { bulletinExtendedSchema, bulletinSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getBulletin(id: number) {
  try {
    const response = await API.get(`bulletins/${id}`)
    const bulletin = bulletinExtendedSchema.parse(response.data)

    return bulletin
  } catch (error) {
    return handleError(error, 'Bulletin')
  }
}

export async function getBulletins() {
  try {
    const response = await API.get('bulletins')
    const bulletins = z.array(bulletinSchema).parse(response.data)

    return bulletins
  } catch (error) {
    return handleError(error, 'Bulletins')
  }
}

//* ---------------- PARAMS ---------------- *//

//* ---------------- HELPERS ---------------- *//
