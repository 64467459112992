import pluralize from 'pluralize'
import QRCode from 'qrcode'
import { redirect, resolvePath } from 'react-router-dom'
import { ALL_GARMENTS } from '../constants'

/**
 *
 * @param currencyCode - ISO 4217 currency code
 * @param amount - amount to be formatted
 * @returns correctly formatted currency string
 */
export const formatMoney = (currencyCode: string, amount: number | string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  })

  return formatter.format(Number(amount))
}

/**
 * @param obj - object to be checked
 * @returns string representation of the object's type
 * @example
 * typeOf('')     // string
 * typeOf(0)      // number
 * typeOf()       // undefined
 * typeOf(null)   // null
 * typeOf({})     // object
 * typeOf([])     // array
 * typeOf(0)      // number
 * typeOf(() => {})  // function
 */
export const typeOf = (obj: unknown) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()

/**
 * @param garments - array of garments
 * @returns concatonated string of pluralized garment types
 * @example
 * 2 Jackets, 1 Pant, 3 Suits
 */
export const getGarmentCount = (garments: TrinityAPI.GarmentType[] | TrinityAPI.GarmentHomeType[]) => {
  const garmentCount: Record<string, number> = ALL_GARMENTS.reduce(
    (acc, garment) => ({ ...acc, [garment.abbreviation]: 0 }),
    {},
  )

  garments.forEach(garment => (garmentCount[garment.garmentType] += 1))

  let garmentList = ''

  for (const [abbreviation, count] of Object.entries(garmentCount)) {
    const garment = ALL_GARMENTS.find(g => g.abbreviation === abbreviation)
    if (count > 0 && garment) {
      garmentList = `${garmentList} ${pluralize(garment.formalName, count, true)},`
    }
  }

  return garmentList.slice(0, -1)
}

/**
 *
 * @param value - string or number or object to be converted to QR code
 * @returns QR code string to be used as src for an image
 */
export const generateQRCode = (value: string | number | Record<string, string | number | null>) => {
  let code
  QRCode.toDataURL(JSON.stringify(value), { errorCorrectionLevel: 'H' }, (err, url) => {
    if (err) throw err
    code = url
  })
  if (typeof code !== 'string') throw new Error('QR Code generation failed')

  return code as string
}

/**
 *
 * @param qrString - return value from QR code scanner
 */
export function handleCollectionFolderQrScan(qrString: string) {
  const resolvedUrl = resolvePath(qrString)
  const collectionId = resolvedUrl.search
    ? resolvedUrl.search.substring(4)
    : resolvedUrl.pathname.substring(resolvedUrl.pathname.length - 4)
  redirect(`/fabric-explorer/collections/${collectionId}`)
}
