import axios, { AxiosError } from 'axios'
import { z } from 'zod'
import { API_URL } from '../constants'

export const API = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

API.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('jwt')

    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`)
    } else {
      delete API.defaults.headers.common.Authorization
    }

    return config
  },

  error => Promise.reject(error),
)

export function handleError(error: unknown, type = 'Unknown') {
  let errorMessage = 'There was an error - please try again.'
  if (error instanceof z.ZodError) {
    errorMessage = error.issues.map(issue => issue.message).join('\n')
  } else if (error instanceof AxiosError) {
    errorMessage = error.response?.data?.message ?? error.message
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else {
    errorMessage = 'There was an error - please try again.'
  }

  console.error(error)
  return Promise.reject(new Error(`${type} - ${errorMessage}`, { cause: error }))
}

export function handleZodError<T extends Record<string, unknown>>(error: z.ZodError<T>) {
  const formatedErrors = error.format()
  const errors: Record<string, unknown> = {}
  for (const [key, value] of Object.entries(formatedErrors)) {
    if (value && !Array.isArray(value)) {
      errors[key] = value._errors[0]
    }
  }
  return errors
}

export function handleConflictError(error: unknown, type = 'Unknown') {
  if (error instanceof AxiosError && error.response?.status === 409) {
    return Promise.resolve({
      error: true,
      message: String(error.response?.data?.errors ?? 'Sorry - Something went wrong.'),
    })
  } else {
    return handleError(error, type)
  }
}
