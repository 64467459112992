import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { clientSchema } from './client'
import { baseGarmentHomeSchema, garmentSchema } from './garment'
import { fabricSchema } from './fabric'
import { delayStatusSchema, orderStatusSchema } from './status'
import { deliveryGarmentBoxSchema } from './shipment'
import { chargeSchema, currencySchema } from './payment'
import { addressSchema } from './address'
import { dealerSchema } from './user'

//* ---------------- BASE ---------------- *//
const baseOrderSchema = z.object({
  id: z.number(),
  title: z.string(),
  custom_order_number: z.string().nullable(),
  garment_count: z.number(),
  ship_type: z.string(),
  ship_cost: z.string(),
  subtotal: z.string(),
  dealer_discount: z.string(),
  total_discount: z.string().nullable(),
  tax: z.string(),
  grand_total: z.string(),
  deposit_percentage: z.number(),
  current_balance: z.string(),
  measurement_units: z.string(),
  payment_status: z.string(),
  ordered_at: z.coerce.date().nullable(),
  created_at: z.coerce.date(),
})

const baseOrderHomeGarmentSchema = baseGarmentHomeSchema.extend({
  fabric: fabricSchema,
  order_status: orderStatusSchema,
  delay_status: delayStatusSchema,
  shipment: deliveryGarmentBoxSchema.nullable(),
})

const baseOrderTypeSchema = z.union([z.literal('MTM'), z.literal('MTO'), z.literal('TIE'), z.literal('Unknown')])

//* ---------------- SCHEMAS ---------------- *//
export const orderSchema = baseOrderSchema.extend({ currency: currencySchema }).transform(objectToCamel)

export const orderHomeGarmentSchema = baseOrderHomeGarmentSchema.transform(objectToCamel)

export const orderHomeSchema = baseOrderSchema
  .extend({
    order_type: baseOrderTypeSchema,
    customer: clientSchema,
    garments: z.array(orderHomeGarmentSchema),
  })
  .transform(objectToCamel)

export const orderExtendedSchema = baseOrderSchema
  .extend({
    order_type: baseOrderTypeSchema,
    order_source: z.string(),
    card_token: z.string().nullable(),
    currency: currencySchema,
    shipping_address: addressSchema,
    dealer: dealerSchema,
    customer: clientSchema,
    garments: z.array(garmentSchema),
    dealer_charges: z.array(chargeSchema),
  })
  .transform(objectToCamel)

export const metricsSchema = z
  .object({
    delays: z.number(),
    incompletes: z.number(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type OrderType = z.infer<typeof orderSchema>
    type OrderHomeGarmentType = z.infer<typeof orderHomeGarmentSchema>
    type OrderHomeType = z.infer<typeof orderHomeSchema>
    type OrderExtendedType = z.infer<typeof orderExtendedSchema>
    type MetricsType = z.infer<typeof metricsSchema>
  }
}
