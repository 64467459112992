import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//
export const baseBulletinSchema = z.object({
  id: z.number(),
  title: z.string(),
  date: z.coerce.date(),
  detail: z.string(),
})

//* ---------------- SCHEMAS ---------------- *//
export const bulletinSchema = baseBulletinSchema.transform(objectToCamel)

export const bulletinExtendedSchema = baseBulletinSchema
  .extend({
    description: z.string(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type BulletinType = z.infer<typeof bulletinSchema>
    type BulletinExtendedType = z.infer<typeof bulletinExtendedSchema>
  }
}
