import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'

//* ---------------- APIS ---------------- *//
export async function setHiddenGarment(rawParams: Record<string, unknown>) {
  try {
    const { id, ...params } = setHiddenGarmentParamsSchema.parse(rawParams)
    const response = await API.get(`garments/${id}/closet_visibility`, { params })

    return response
  } catch (error) {
    return handleError(error, 'Garment')
  }
}

//* ---------------- PARAMS ---------------- *//
export const setHiddenGarmentParamsSchema = z
  .object({
    id: z.coerce.number(),
    isHidden: z.enum(['0', '1']),
  })
  .transform(objectToSnake)

//* ---------------- TYPES ---------------- *//
