import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//

//* ---------------- SCHEMAS ---------------- *//
export const addressSchema = z
  .object({
    id: z.number(),
    contact_name: z.string().nullable(),
    description: z.string(),
    street1: z.string(),
    street2: z.string().nullable(),
    street3: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    zip: z.string().nullable(),
    country: z.string(),
    phone: z.string().nullable(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type AddressType = z.infer<typeof addressSchema>
  }
}
