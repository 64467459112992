import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { ID, Shipment, T2ID } from '../../constants'
import { addressSchema } from './address'
import { fabricOrderSchema } from './fabricOrder'
import { garmentSchema } from './garment'
import { supplierSchema } from './user'

//* ---------------- BASE ---------------- *//
const baseShipmentSchema = z.object({
  id: z.number(),
  description: z.string(),
  status: z.enum(Shipment.Status.VALID),
  method: z.string().nullable(),
  carrier: z.string().nullable(),
  tracking_number: z.string().nullable(),
  receive_date: z.coerce.date().nullable(),
  create_date: z.coerce.date(),
  ship_date: z.coerce.date().nullable(),
  shipment_type: z.enum(Shipment.Type.VALID),
  item_count: z.number(),
  manufacturer_destination: z.enum([ID, T2ID]).nullable(),
  tracking_link: z.string().url().nullable(),
})

//* ---------------- SCHEMAS ---------------- *//
export const shipmentSchema = baseShipmentSchema.transform(objectToCamel)

export const shipmentExtendedSchema = baseShipmentSchema
  .extend({
    destination: addressSchema,
    fabric_orders: z.lazy(() => fabricOrderSchema.array()),
    login_id: z.number(),
    shipment_items: garmentSchema.array(),
    source: addressSchema,
    supplier: supplierSchema,
  })
  .transform(objectToCamel)

export const deliveryGarmentBoxSchema = z
  .object({
    box_id: z.number(),
    carrier: z.string().nullable(),
    tracking_number: z.string().nullable(),
    link: z.string().nullable(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type ShipmentType = z.infer<typeof shipmentSchema>
    type ShipmentExtendedType = z.infer<typeof shipmentExtendedSchema>
    type DeliveryGarmentBoxType = z.infer<typeof deliveryGarmentBoxSchema>
  }
}
