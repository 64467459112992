import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'
import { optionGroupSchema, optionSchema, optionValueSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getOptions(rawParams: Record<string, unknown>) {
  try {
    const params = getOptionsParamsSchema.parse(rawParams)

    const response = await API.get('options', {
      params: { active: 1, option_control_type: ['select', 'checkbox'], ...params },
    })
    const options = z.array(optionSchema).parse(response.data)

    return { options, totalOptions: response.headers.total }
  } catch (error) {
    return handleError(error, 'Options')
  }
}

export async function getOptionGroups(rawParams: Record<string, unknown>) {
  try {
    const INACTIVE_OPTIONS = 33
    const MATERIALS_OPTIONS = 39
    const params = getOptionGroupParamsSchema.parse(rawParams)
    const response = await API.get('option_groups', { params })
    const optionGroups = z
      .array(optionGroupSchema)
      .parse(response.data)
      .filter(group => ![INACTIVE_OPTIONS, MATERIALS_OPTIONS].includes(group.id))

    return optionGroups
  } catch (error) {
    return handleError(error, 'Option Groups')
  }
}

export async function getOptionValues(rawParams: Record<string, unknown>) {
  try {
    const params = getOptionValuesParamsSchema.parse(rawParams)
    const response = await API.get('option_values', { params: { active: 1, ...params } })
    const optionValues = z.array(optionValueSchema).parse(response.data)

    return { optionValues, totalOptionValues: response.headers.total }
  } catch (error) {
    return handleError(error, 'Option Values')
  }
}

//* ---------------- PARAMS ---------------- *//
const getOptionGroupParamsSchema = z
  .object({
    garmentType: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const getOptionsParamsSchema = z
  .object({
    location: z.string().optional(),
    optionGroupId: z.coerce.number().optional(),
    garmentType: z.coerce.number().optional(),
    tailoringGrade: z.number().optional(),
    page: z.number().optional().default(1),
    perPage: z.number().optional(),
    q: z.string().optional(),
  })
  .transform(objectToSnake)

const getOptionValuesParamsSchema = z
  .object({
    optionId: z.coerce.number().optional(),
    defaultValue: z.boolean().optional(),
    garmentType: z.coerce.number().optional(),
    tailoringGrade: z.number().optional(),
    q: z.string().optional(),
    page: z.number().optional().default(1),
    perPage: z.number().optional(),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
