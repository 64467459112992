import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//
const baseClientSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  home_phone: z.string().nullable(),
  cell: z.string().nullable(),
  email: z.string().nullable(),
})

const baseClientExtendedSchema = baseClientSchema.extend({
  first_name: z.string(),
  last_name: z.string(),
  initials: z.string().nullable(),
  created_at: z.coerce.date().nullable(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  country: z.string().nullable(),
  work_phone: z.string().nullable(),
  fax: z.string().nullable(),
  company: z.string().nullable(),
  gender: z.string().nullable(),
  receive_email_promos: z.boolean(),
  receive_mail_promos: z.boolean(),
  name_label: z.string().nullable(),
  laundry_marker: z.string().nullable(),
  monogram: z.string().nullable(),
  notes: z.string().nullable(),
  dealer_personal: z.boolean(),
  first_order: z.coerce.date().nullable(),
  last_order: z.coerce.date().nullable(),
})

const totalsSchema = z.object({
  total_orders: z.number(),
  total_garments: z.number(),
})

//* ---------------- SCHEMAS ---------------- *//
export const clientSchema = baseClientSchema.transform(objectToCamel)

export const clientExtendedSchema = baseClientExtendedSchema.transform(objectToCamel)

export const clientDashboardSchema = baseClientExtendedSchema
  .pick({
    id: true,
    first_order: true,
    last_order: true,
    name: true,
  })
  .merge(totalsSchema)
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type ClientType = z.infer<typeof clientSchema>
    type ClientExtendedType = z.infer<typeof clientExtendedSchema>
    type ClientDashboardType = z.infer<typeof clientDashboardSchema>
  }
}
