import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//

//* ---------------- SCHEMAS ---------------- *//
export const searchSchema = z
  .object({
    id: z.number(),
    type: z.union([
      z.literal('fabric'),
      z.literal('collection'),
      z.literal('customer'),
      z.literal('order'),
      z.literal('garment'),
    ]),
    name: z.string(),
    dealer_order_id: z.number().nullable().optional(),
    description: z.string().nullable().optional(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type SearchType = z.infer<typeof searchSchema>
  }
}
