import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { AxiosError } from 'axios'
import { API, handleError } from './api'
import { metricsSchema, orderExtendedSchema, orderHomeSchema } from './schemas'
import { cleanObject } from '../helpers'

//* ---------------- APIS ---------------- *//
export async function getOrders(rawParams: Record<string, unknown>) {
  try {
    const params = getOrdersParams.parse(rawParams)
    const response = await API.get('home_orders', { params })
    const orders = z.array(orderHomeSchema).parse(response.data)
    const totalOrders = Number(response.headers.total)

    return { orders, totalOrders }
  } catch (error) {
    return handleError(error, 'Orders')
  }
}

export async function getExtendedOrders(rawParams: Record<string, unknown>) {
  try {
    const params = getExtendedOrdersParams.parse(cleanObject(rawParams))
    const response = await API.get('orders', { params: { extended: 1, ...params } })
    const orders = z.array(orderExtendedSchema).parse(response.data)

    return { orders, totalOrders: response.headers.total }
  } catch (error) {
    return handleError(error, 'Extended Orders')
  }
}

export async function getMetrics(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMetricsParams.parse(rawParams)
    const response = await API.get('orders/metrics', { params })
    const metrics = metricsSchema.parse(response.data)

    return metrics
  } catch (error) {
    return handleError(error, 'Metrics')
  }
}

export async function chargeBalance(rawParams: Record<string, unknown>) {
  try {
    const { order_id, ...params } = chargeBalanceParams.parse(rawParams)
    const response = await API.post(`orders/${order_id}/run_balance`, { ...params })

    return { error: false, data: response.data, message: response.data.message }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 422) {
      return { error: true, data: null, message: error.response.data.message } as const
    }
    return handleError(error, 'Charge Balance')
  }
}

export async function transferBalance(rawParams: Record<string, unknown>) {
  try {
    const params = transferBalanceParams.parse(rawParams)
    const response = await API.post('orders/transfer_balance', params)

    return { error: false, data: response.data, message: response.data.message }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 422) {
      return { error: true, data: null, message: error.response.data.message } as const
    }
    return handleError(error, 'Transfer Balance')
  }
}

//* ---------------- PARAMS ---------------- *//
const getOrdersParams = z
  .object({
    scope: z.string().default('all'),
    customerId: z.number().optional(),
    sortOrder: z.enum(['asc', 'desc']).default('desc'),
    page: z.number().default(1),
    perPage: z.number().default(100),
  })
  .transform(objectToSnake)

const getExtendedOrdersParams = z
  .object({
    customerId: z.number().optional(),
    paymentStatus: z.number().optional(),
    balanceOnly: z.boolean().optional(),
    reverseSort: z.boolean().optional(),
    page: z.number().optional().default(1),
    perPage: z.number().optional().default(10000),
  })
  .transform(objectToSnake)

const getMetricsParams = z
  .object({
    customerId: z.number().optional(),
  })
  .transform(objectToSnake)

const chargeBalanceParams = z
  .object({
    orderId: z.string(),
    paymentMethodToken: z.string(),
    allowBalancePrepay: z.enum(['true', 'false']).default('true'),
  })
  .transform(objectToSnake)

const transferBalanceParams = z
  .object({
    orderId: z.union([z.string(), z.array(z.string())]),
    paymentMethodToken: z.string(),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
