import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'
import { PRICE_PROFILES, TailoringGradeArray } from '../constants'
import { cleanObject } from '../helpers'
import { priceSchema, priceTierSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getPrices(rawParams: Record<string, unknown>) {
  try {
    const validatedParams = getPricesParamsSchema.parse(rawParams)
    const params = cleanObject(validatedParams)
    const response = await API.get('fabric_prices', { params })
    const price = z.array(priceSchema).parse(response.data)

    return price
  } catch (error) {
    return handleError(error, 'Prices')
  }
}

//* ---------------- PARAMS ---------------- *//
const getPricesParamsSchema = z
  .object({
    tailoringGrade: z.enum(TailoringGradeArray).optional().default(TailoringGradeArray[0]),
    currency: z.coerce.number().optional().default(PRICE_PROFILES[0].id),
    calculatorType: z
      .union([z.literal('markup'), z.literal('margin')])
      .nullable()
      .default('margin'),
    priceCalculator: z.coerce.number().optional(),
    priceTier: z.array(priceTierSchema).optional(),
    priceCode: z.array(z.string()).optional(),
    namedFilter: z.array(z.string()).optional(),
  })
  .transform(({ calculatorType, priceCalculator, currency, ...data }) => ({
    ...data,
    [calculatorType as string]: priceCalculator,
    priceProfileId: currency,
  }))
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
