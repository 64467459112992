import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

export function initSentry(dsn: string, release: string) {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: ['https://api.trinity-apparel.com/v1/'],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.trinity-apparel.com\/v1/],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 20% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Environment Information
    release,
    environment: import.meta.env.MODE,
    beforeSend(event) {
      if (import.meta.env.DEV) {
        return null
      }

      return event
    },
  })
}

export function logToSentry(error: unknown) {
  if (import.meta.env.DEV) {
    console.error(error)
  } else {
    Sentry.captureException(error)
  }
}
