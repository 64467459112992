import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//
const baseDealerSchema = z.object({
  id: z.number(),
  name: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  company_name: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  country: z.string().nullable(),
  logo: z.string().nullable(),
})

const baseTrinityUserSchema = z.object({
  id: z.number(),
  description: z.string(),
  email: z.string().nullable(),
  permissions: z
    .object({
      admin: z.boolean(),
      master: z.boolean(),
      pricing: z.boolean(),
      credit_card: z.boolean(),
      master_reports: z.boolean(),
      qb_reports: z.boolean(),
      dealer_reports: z.boolean(),
      tmc_reports: z.boolean(),
      options: z.boolean(),
      fabrics: z.boolean(),
      permissions: z.boolean(),
    })
    .transform(objectToCamel),
})

const baseAgentSchema = z.object({
  id: z.number(),
  agent_type: z.number(),
  agent_fname: z.string(),
  agent_lname: z.string(),
  agent_email: z.string().nullable(),
  notes: z.string().nullable(),
  created_at: z.string(),
  name: z.string(),
})

const baseManufacturerSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string().nullable(),
})

const baseSupplierSchema = z.object({
  city: z.string(),
  code: z.string(),
  country: z.string(),
  id: z.number(),
  name: z.string(),
  contact_name: z.string().nullable(),
  email: z.string().nullable(),
  state: z.string().nullable(),
  units: z.union([z.literal('imperial'), z.literal('metric')]),
})

//* ---------------- SCHEMAS ---------------- *//
export const jwtSchema = z
  .object({
    auth_token: z.string(),
  })
  .transform(objectToCamel)

export const dealerSchema = baseDealerSchema.transform(objectToCamel)

export const trinityUserSchema = baseTrinityUserSchema.transform(objectToCamel)

export const agentSchema = baseAgentSchema.transform(objectToCamel)

export const manufacturerSchema = baseManufacturerSchema.transform(objectToCamel)

export const supplierSchema = baseSupplierSchema.transform(objectToCamel)

export const loginSchema = z
  .object({
    id: z.number(),
    username: z.string(),
    status: z.string(),
    created_at: z.coerce.date(),
    name: z.string(),
    locale: z.string(),
    is_active: z.boolean(),
    user_hash: z.string().nullable(),
    roles: z.object({
      dealer: z.boolean(),
      staff: z.boolean(),
      manufacturer: z.boolean(),
      agent: z.boolean(),
      admin: z.boolean(),
      supplier: z.boolean(),
    }),
    permissions: z
      .object({
        nav: z.array(z.string()),
        order_types: z
          .object({
            can_order_mtm: z.boolean(),
            can_order_mto: z.boolean(),
          })
          .transform(objectToCamel)
          .nullable(),
      })
      .transform(objectToCamel),
    dealer: dealerSchema.nullable(),
    trinity_user: trinityUserSchema.nullable(),
    agent: agentSchema.nullable(),
    manufacturer: manufacturerSchema.nullable(),
    supplier: supplierSchema.nullable(),
    token: z.string(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type DealerType = z.infer<typeof dealerSchema>
    type TrinityUserType = z.infer<typeof trinityUserSchema>
    type AgentType = z.infer<typeof agentSchema>
    type ManufacturerType = z.infer<typeof manufacturerSchema>
    type SupplierType = z.infer<typeof supplierSchema>
    type LoginType = z.infer<typeof loginSchema>
    type JWTType = z.infer<typeof jwtSchema>
  }
}
