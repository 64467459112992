import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//
const baseOptionGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  garment_type: z.number(),
  display_order: z.number(),
})

const baseOptionSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  display_order: z.number(),
  garment_type: z.number(),
  location: z.string(),
  active: z.boolean(),
  option_group: baseOptionGroupSchema.transform(objectToCamel),
})

//* ---------------- SCHEMAS ---------------- *//
export const optionGroupSchema = baseOptionGroupSchema.transform(objectToCamel)

export const manufacturerOptionValueSchema = z
  .object({
    id: z.number(),
    locale: z.union([z.literal('en'), z.literal('id'), z.literal('zh')]).nullable(),
    description: z.string().nullable(),
    chinese_description: z.string().nullable(),
  })
  .transform(objectToCamel)

export const optionSchema = baseOptionSchema.transform(objectToCamel)

export const optionValueSchema = z
  .object({
    id: z.number(),
    option_value: z.string(),
    description: z.string(),
    image: z.string(),
    display_order: z.number(),
    active: z.boolean(),
    has_image: z.boolean(),
    garment_type: z.number(),
    default_value: z.boolean(),
    option: baseOptionSchema
      .pick({ id: true, name: true, description: true, display_order: true })
      .transform(objectToCamel),
    manufacturer_option_values: z.array(manufacturerOptionValueSchema),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type OptionGroupType = z.infer<typeof optionGroupSchema>
    type ManufacturerOptionValueType = z.infer<typeof manufacturerOptionValueSchema>
    type OptionType = z.infer<typeof optionSchema>
    type OptionValueType = z.infer<typeof optionValueSchema>
  }
}
