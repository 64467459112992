export const EMAIL_REGEX = /\S+@\S+\.\S+/

export const COUNTRIES = [
  'USA',
  'Austria',
  'Australia',
  'Canada',
  'China',
  'Egypt',
  'France',
  'Germany',
  'Ghana',
  'Hong Kong',
  'India',
  'Jordan',
  'Netherlands',
  'New Zealand',
  'Poland',
  'Puerto Rico',
  'Russia',
  'Singapore',
  'South Africa',
  'Sweden',
  'Switzerland',
  'U.K.',
  'United Arab Emirates',
] as const

export const US_STATES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const CA_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT']
