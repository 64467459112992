import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { ALL_GARMENTS } from '../../constants'

//* ---------------- BASE ---------------- *//
const baseGarmentSchema = z.object({
  id: z.number(),
  title: z.string(),
  order_id: z.number(),
  copied_garment_id: z.number().nullable(),
  price: z.string(),
  option_cost: z.string(),
  garment_type: z.string(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date().nullable(),
})

export const baseGarmentHomeSchema = baseGarmentSchema.extend({
  status_updated_at: z.coerce.date(),
})

//* ---------------- SCHEMAS ---------------- *//
export const garmentSchema = baseGarmentSchema
  .extend({
    order_status_id: z.number(),
    delay_status_id: z.number(),
    fabric_url: z.string().url().nullable(),
    shipping_class: z.union([z.literal('direct'), z.literal('bulk')]),
  })
  .transform(objectToCamel)

export const garmentHomeSchema = baseGarmentHomeSchema.transform(objectToCamel)

export const baseModelSchema = z
  .object({
    id: z.number(),
    model: z.string(),
    description: z.string(),
    image: z.string(),
    garment_type: z.number(),
    tailoring_grade: z.number(),
    order_type: z.number(),
    active: z.boolean(),
    has_image: z.boolean(),
    display_order: z.number(),
  })
  .transform(objectToCamel)

export const garmentRenderSchema = z
  .object({
    id: z.number(),
    title: z.string(),
    order_id: z.number(),
    garment_type: z.number(),
    created_at: z.coerce.date(),
    trinity_fabric_number: z.string(),
    fabric_description: z.string(),
    uri: z.string().url().nullable(),
    is_hidden: z.boolean(),
    collection: z.string(),
    lining: z.string().nullable(),
    button: z.string().nullable(),
  })
  .transform(objectToCamel)
  .transform(({ garmentType, ...data }) => ({
    ...data,
    garmentType: ALL_GARMENTS.find(g => g.bitmask === garmentType) || throwError(),
  }))

export const garmentTypeSchema = z.number()

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type GarmentType = z.infer<typeof garmentSchema>
    type GarmentHomeType = z.infer<typeof garmentHomeSchema>
    type BaseModelType = z.infer<typeof baseModelSchema>
    type GarmentRenderType = z.infer<typeof garmentRenderSchema>
    type GarmentTypeType = z.infer<typeof garmentTypeSchema>
  }
}

//* ---------------- HELPERS ---------------- *//
const throwError = () => {
  throw new Error('Invalid garment type')
}
