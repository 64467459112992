import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { FabricOrderStatus, ID, T2ID } from '../../constants'
import { addressSchema } from './address'
import { fabricExtendedSchema } from './fabric'
import { garmentSchema } from './garment'
import { shipmentSchema } from './shipment'

//* ---------------- BASE ---------------- *//
const baseFabricOrderSchema = z.object({
  id: z.number(),
  fabric_id: z.number(),
  garment_id: z.number().nullable(),
  length: z.coerce.number(),
  address_id: z.number(),
  extra_fabric: z.boolean(),
  fulfillment_failure: z.boolean(),
  shipment_id: z.number().nullable(),
  reason: z.string().nullable(),
  created_at: z.coerce.date(),
  status: z.enum(FabricOrderStatus.VALID),
  trinity_fabric_number: z.string(),
  supplier_fabric_number: z.string(),
  description: z.string(),
  image: z.string().url(),
  material_type: z.string(),
  manufacturer_description: z.enum([ID, T2ID]),
  fabric_composition: z.string(),
  fabric_weight: z.coerce.number(),
  fabric_price: z.coerce.number(),
  bolt_order: z.boolean(),
  dealer: z
    .object({
      name: z.string().nullable(),
      state: z.string().nullable(),
    })
    .optional(),
})

//* ---------------- SCHEMAS ---------------- *//
export const fabricOrderSchema = baseFabricOrderSchema.transform(objectToCamel)

export const extendedFabricOrderSchema = baseFabricOrderSchema
  .extend({
    fabric_composition: z.string(),
    fabric: fabricExtendedSchema,
    address: addressSchema,
    garment: garmentSchema.nullable(),
    shipment: shipmentSchema.nullable(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type FabricOrderType = z.infer<typeof fabricOrderSchema>
    type FabricOrderExtendedType = z.infer<typeof extendedFabricOrderSchema>
  }
}
