import { AxiosError } from 'axios'
import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'
import { FabricOrderStatus } from '../constants'
import { extendedFabricOrderSchema, fabricOrderSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getFabricOrders(rawParams: Record<string, unknown>) {
  try {
    const params = getFabricOrdersParamsSchema.parse(rawParams)
    const response = await API.get('fabric_orders', { params })
    const fabricOrders = z.array(fabricOrderSchema).parse(response.data)

    return { fabricOrders, totalFabricOrders: Number(response.headers.total) }
  } catch (error) {
    return handleError(error, 'Fabric Orders')
  }
}

export async function getFabricOrder(fabricOrderId: number) {
  try {
    const response = await API.get(`fabric_orders/${fabricOrderId}`)
    const fabricOrder = extendedFabricOrderSchema.parse(response.data)

    return fabricOrder
  } catch (error) {
    return handleError(error, 'Fabric Order')
  }
}

export async function addFabricOrderToShipment(rawParams: Record<string, unknown>) {
  try {
    const params = addFabricOrderToShipmentParamsSchema.parse(rawParams)
    await API.post('fabric_orders/shipment', params)

    return {
      error: false,
      message: `Fabric Order(s) ${params.fabric_order_id.join(', ')} have been successfully added to the shipment`,
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      return { error: true, message: String(error.response.data.errors) }
    } else {
      return handleError(error, 'Add Fabric Orders To Shipment')
    }
  }
}

//* ---------------- PARAMS ---------------- *//
const getFabricOrdersParamsSchema = z
  .object({
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(25),
    status: z.string().default(FabricOrderStatus.PENDING),
    factory: z.string().default('all'),
    q: z.string().optional(),
    boltOrder: z.union([z.literal(0), z.literal(1)]).default(0),
  })
  .transform(({ factory, q, ...data }) => ({
    ...data,
    destination: factory === 'all' ? null : factory,
    supplierFabricNumber: q,
  }))
  .transform(objectToSnake)

const addFabricOrderToShipmentParamsSchema = z
  .object({
    fabricOrderIds: z.array(z.coerce.number()),
  })
  .transform(({ fabricOrderIds }) => ({ fabric_order_id: fabricOrderIds }))

//* ---------------- HELPERS ---------------- *//
