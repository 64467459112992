import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//
const baseButtonSchema = z.object({
  value: z.string(),
  description: z.string(),
  code: z.string().nullable(),
  title: z.string(),
  image: z.string().url(),
  rgb: z.string().nullable(),
  display_order: z.number(),
  active: z.boolean(),
})

//* ---------------- SCHEMAS ---------------- *//
export const buttonSchema = baseButtonSchema.transform(objectToCamel)

export const feltSchema = buttonSchema

export const suedeSchema = buttonSchema

export const pipingSchema = z
  .object({
    id: z.number(),
    trinity_fabric_number: z.string(),
    lining_fabric_number: z.string(),
    description: z.string(),
    image: z.string().url(),
    active: z.boolean(),
  })
  .transform(objectToCamel)

export const nameLabelSchema = baseButtonSchema.omit({ code: true, title: true }).transform(objectToCamel)

export const threadSchema = baseButtonSchema.omit({ title: true }).transform(objectToCamel)

export const trouserTrimSchema = z
  .object({
    id: z.number(),
    value: z.string(),
    description: z.string(),
    image: z.string().url(),
    display_order: z.number(),
    active: z.boolean(),
    created_at: z.coerce.date(),
    updated_at: z.coerce.date(),
  })
  .transform(objectToCamel)

export const zippersSchema = trouserTrimSchema

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type ButtonType = z.infer<typeof buttonSchema>
    type FeltType = z.infer<typeof feltSchema>
    type SuedeType = z.infer<typeof suedeSchema>
    type PipingType = z.infer<typeof pipingSchema>
    type NameLabelType = z.infer<typeof nameLabelSchema>
    type ThreadType = z.infer<typeof threadSchema>
    type TrouserTrimType = z.infer<typeof trouserTrimSchema>
    type ZipperType = z.infer<typeof zippersSchema>
  }
}
