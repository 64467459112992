import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'

//* ---------------- BASE ---------------- *//

//* ---------------- SCHEMAS ---------------- *//
export const creditCardSchema = z
  .object({
    card_type: z.string(),
    cardholder_name: z.string(),
    expiration_month: z.string(),
    expiration_year: z.string(),
    gateway_type: z.string(),
    is_primary: z.boolean(),
    last_4: z.string(),
    logo: z.string(),
    token: z.string(),
    zip_code: z.string().nullable(),
  })
  .transform(objectToCamel)

export const currencySchema = z.object({
  name: z.string(),
  symbol: z.string(),
  rate: z.string(),
})

export const chargeSchema = z
  .object({
    gateway: z.union([z.literal('braintree'), z.literal('payflow')]),
    charge_date: z.coerce.date(),
    charge_creation_date: z.coerce.date(),
    charge_amount: z.string(),
    charge_active: z.boolean(),
    card_type: z.string().nullable(),
    last_4: z.string().nullable(),
    charge_type: z.string(),
    transaction_type: z.string(),
    response_message: z.string().nullable(),
    currency_id: z.number(),
    id: z.number(),
    transaction_result_code: z.string().nullable(),
    merchant_initiated: z.boolean(),
  })
  .transform(objectToCamel)

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type CreditCardType = z.infer<typeof creditCardSchema>
    type CurrencyType = z.infer<typeof currencySchema>
    type ChargeType = z.infer<typeof chargeSchema>
  }
}
