import { z } from 'zod'
import { AxiosError } from 'axios'
import { API, handleError } from './api'
import { creditCardSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getCreditCards() {
  try {
    const response = await API.get('credit_cards')
    const creditCards = z.array(creditCardSchema).parse(response.data)

    return creditCards
  } catch (error) {
    return handleError(error, 'Credit Cards')
  }
}

export async function getClientToken() {
  try {
    const response = await API.get('credit_cards/client_token')
    const clientToken = z.string().parse(response.data.client_token)

    return clientToken
  } catch (error) {
    return handleError(error, 'Client Token')
  }
}

export async function createCreditCard(rawParams: Record<string, unknown>) {
  try {
    const params = creditCardParamsSchema.parse(rawParams)
    const result = await API.post('credit_cards', params)

    return { error: false, data: result.data, message: 'Card Successfully Added' } as const
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 422) {
      return { error: true, data: null, message: 'Unable to Save Card' } as const
    }
    return handleError(error, 'Create Credit Card')
  }
}

export async function updateCreditCard(rawParams: Record<string, unknown>) {
  try {
    const params = updateCreditCardParamsSchema.parse(rawParams)
    const result = await API.put(`credit_cards/${params.token}`, params)

    return result
  } catch (error) {
    return handleError(error, 'Update Credit Card')
  }
}

export async function deleteCreditCard(token: string) {
  try {
    await API.delete(`credit_cards/${token}`)

    return { error: false, data: null } as const
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 422) {
      return { error: true, data: (error.response?.data?.orders as string[]) ?? [] } as const
    }
    return handleError(error, 'Delete Credit Card')
  }
}

//* ---------------- PARAMS ---------------- *//
const creditCardParamsSchema = z.object({
  nonce: z.string(),
  default: z.union([z.literal('true'), z.literal('false')]).transform(val => val === 'true'),
})

const updateCreditCardParamsSchema = creditCardParamsSchema.extend({
  token: z.string(),
})

//* ---------------- HELPERS ---------------- *//
