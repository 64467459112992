//* ---------------- GARMENTS ---------------- *//
export class GarmentStatus {
  static INCOMPLETE = 'Incomplete' as const
  static CMT_FABRIC_HOLD = 'CMT Fabric Hold' as const
  static FABRIC_HOLD = 'Fabric Hold' as const
  static READY = 'Ready' as const
  static BLUE_PENCIL = 'Blue Pencil' as const
  static CUTTING = 'Cutting' as const
  static PRODUCTION = 'Production' as const
  static PRODUCTION_COMPLETE = 'Production Complete' as const
  static INTERNATIONAL_TRANSIT = 'International Transit' as const
  static FINAL_INSPECTION = 'Final Inspection' as const
  static SHIPMENT_PROCESSING = 'Shipment Processing' as const
  static DELIVERY = 'Delivery' as const
  static DIRECT_SHIP = 'Direct Ship' as const
  static CANCELED = 'Canceled' as const
  static VALID = [
    this.INCOMPLETE,
    this.CMT_FABRIC_HOLD,
    this.FABRIC_HOLD,
    this.READY,
    this.BLUE_PENCIL,
    this.CUTTING,
    this.PRODUCTION,
    this.PRODUCTION_COMPLETE,
    this.INTERNATIONAL_TRANSIT,
    this.FINAL_INSPECTION,
    this.SHIPMENT_PROCESSING,
    this.DELIVERY,
    this.DIRECT_SHIP,
    this.CANCELED,
  ] as const
  static EDITABLE = [this.FABRIC_HOLD, this.CMT_FABRIC_HOLD, this.READY] as const
  static DELIVERABLE = [this.DELIVERY, this.DIRECT_SHIP] as const
}

//* ---------------- FABRIC ORDERS ---------------- *//
export class FabricOrderStatus {
  static ACCEPTED = 'accepted' as const
  static CANCELLED = 'cancelled' as const
  static PACKING = 'packing' as const
  static PENDING = 'pending' as const
  static RECEIVED = 'received' as const
  static REJECTED = 'rejected' as const
  static TRANSIT = 'transit' as const
  static VALID = [
    this.ACCEPTED,
    this.CANCELLED,
    this.PACKING,
    this.PENDING,
    this.RECEIVED,
    this.REJECTED,
    this.TRANSIT,
  ] as const
}

//* ---------------- FABRICS ---------------- *//
export const IN_STOCK = 'inStock'
export const TEMP_OUT = 'tempOut'
export const UNAVAILABLE = 'unavailable'

//* ---------------- SHIPMENTS ---------------- *//
export class Shipment {
  static Status = class {
    static PENDING = 'pending' as const
    static TRANSIT = 'transit' as const
    static RECEIVED = 'received' as const
    static VALID = [this.PENDING, this.TRANSIT, this.RECEIVED] as const
  }

  static Type = class {
    static GARMENT_ORDER = 'garment order' as const
    static FABRIC_ORDER = 'fabric order' as const
    static DEALER_ORDER = 'dealer order' as const
    static DIRECT_SHIP = 'direct ship' as const
    static BOLT_ORDER = 'bolt order' as const
    static VALID = [
      this.GARMENT_ORDER,
      this.FABRIC_ORDER,
      this.DEALER_ORDER,
      this.DIRECT_SHIP,
      this.BOLT_ORDER,
    ] as const
  }
}
