import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { fabricExtendedSchema } from './fabric'

//* ---------------- BASE ---------------- *//
const baseCollectionSchema = z.object({
  id: z.union([z.number(), z.literal('favorites')]),
  name: z.string(),
  title: z.string(),
  datecode: z.string(),
  image: z.string(),
  digital_pdf_url: z.string().nullable(),
  brand: z.string().nullable(),
  year: z.number().nullable(),
})

//* ---------------- SCHEMAS ---------------- *//
export const collectionSchema = baseCollectionSchema.transform(objectToCamel)

export const collectionExtendedSchema = baseCollectionSchema
  .extend({
    description: z.string().nullable(),
    fabrics: z.array(fabricExtendedSchema),
  })
  .transform(objectToCamel)

export const collectionBrandSchema = z
  .object({
    id: z.number(),
    brand: z.string(),
    is_active: z.boolean(),
    created_at: z.coerce.date(),
    updated_at: z.coerce.date(),
  })
  .transform(objectToCamel)

export const collectionFiltersSchema = z.object({
  brands: z.array(z.string()),
  years: z.array(z.coerce.string()),
})

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type CollectionType = z.infer<typeof collectionSchema>
    type CollectionExtendedType = z.infer<typeof collectionExtendedSchema>
    type CollectionBrandType = z.infer<typeof collectionBrandSchema>
    type CollectionFiltersType = z.infer<typeof collectionFiltersSchema>
  }
}
