import { z } from 'zod'

//* ---------------- BASE ---------------- *//

//* ---------------- SCHEMAS ---------------- *//
export const priceTierSchema = z.union([
  z.literal('1'),
  z.literal('2'),
  z.literal('3'),
  z.literal('4'),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
])

export const priceCodeSchema = z.object({
  code: z.string(),
  description: z.string().nullable(),
  tier: priceTierSchema.nullable(),
})

export const priceSchema = priceCodeSchema
  .extend({
    garment_prices: z
      .object({
        updated_at: z.coerce.date().optional(),
        CSC: z.number().optional(),
        CV: z.number().optional(),
        CT: z.number().optional(),
        CCP: z.number().optional(),
        CSHT: z.number().optional(),
        CSHO: z.number().optional(),
        CCVP: z.number().optional(),
        CCPP: z.number().optional(),
        CCVPP: z.number().optional(),
        CTOPC: z.number().optional(),
        SWK: z.number().optional(),
        CBK: z.number().optional(),
        CASUALJACKET: z.number().optional(),
      })
      .transform(({ updated_at, ...data }) => ({ updatedAt: updated_at, ...data })),
  })
  .transform(({ garment_prices, ...data }) => ({
    garmentPrices: garment_prices,
    ...data,
  }))

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type PriceTierType = z.infer<typeof priceTierSchema>
    type PriceCodeType = z.infer<typeof priceCodeSchema>
    type PriceType = z.infer<typeof priceSchema>
  }
}
