import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'
import {
  dealerCollectionRankSchema,
  dealerGarmentTypesRankSchema,
  dealerLeaderboardSchema,
  dealerRankSchema,
} from './schemas'

//* ---------------- APIS ---------------- *//
export async function getDealerRank() {
  try {
    const response = await API.get('rankings/dealer_stats')
    const stats = dealerRankSchema.parse(response.data)

    return stats
  } catch (error) {
    return handleError(error, 'Reports:Rank')
  }
}

export async function getDealerLeaderboard(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getDealerLeaderboardParams.parse(rawParams)
    const response = await API.get('rankings/dealer_leaderboard', { params })
    const leaderboard = z.array(dealerLeaderboardSchema).parse(response.data)

    return leaderboard
  } catch (error) {
    return handleError(error, 'Reports:Leaderboard')
  }
}

export async function getDealerCollectionRank(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getDealerCollectionRankParams.parse(rawParams)
    const response = await API.get('rankings/dealer_collections', { params })
    const collections = z.array(dealerCollectionRankSchema).parse(response.data)

    return collections
  } catch (error) {
    return handleError(error, 'Reports:Collections')
  }
}

export async function getDealerGarmentTypesRank(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getDealerGarmentTypesRankParams.parse(rawParams)
    const response = await API.get('rankings/dealer_garment_types', { params })
    const garmentTypes = z.array(dealerGarmentTypesRankSchema).parse(response.data)

    return garmentTypes
  } catch (error) {
    return handleError(error, 'Reports:Garment Types')
  }
}

//* ---------------- PARAMS ---------------- *//
const timeframe = z.object({
  timeframe: z.enum(['mtd', 'ytd']).default('mtd'),
})
const getDealerLeaderboardParams = timeframe
  .extend({
    sortBy: z.enum(['revenue', 'units']).default('revenue'),
  })
  .transform(objectToSnake)

const getDealerCollectionRankParams = timeframe.transform(objectToSnake)

const getDealerGarmentTypesRankParams = timeframe.transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
