import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { ALL_GARMENTS, COAT_PANT } from '../../constants'

//* ---------------- BASE ---------------- *//
const baseStatsSchema = z.object({
  rank: z.number(),
  revenue: z.coerce.number(),
  units: z.number(),
})

//* ---------------- SCHEMAS ---------------- *//
export const dealerRankSchema = baseStatsSchema
  .extend({
    id: z.number(),
    revenue_trend: z.number().nullable(),
    units_trend: z.number().nullable(),
  })
  .transform(objectToCamel)

export const dealerLeaderboardSchema = baseStatsSchema
  .extend({
    current: z.boolean(),
  })
  .transform(objectToCamel)

export const dealerCollectionRankSchema = baseStatsSchema
  .omit({ revenue: true })
  .extend({
    id: z.number(),
    name: z.string(),
    image: z.string().url(),
    rank_percentile: z.number(),
  })
  .transform(objectToCamel)

export const dealerGarmentTypesRankSchema = baseStatsSchema
  .omit({ revenue: true })
  .extend({
    garment_type: z.number().nullable(),
    rank_percentile: z.number(),
  })
  .transform(objectToCamel)
  .transform(({ garmentType, ...data }) => ({
    ...data,
    garmentType: ALL_GARMENTS.find(g => g.bitmask === garmentType) || COAT_PANT,
  }))

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type DealerRankType = z.infer<typeof dealerRankSchema>
    type DealerLeaderboardType = z.infer<typeof dealerLeaderboardSchema>
    type DealerCollectionRankType = z.infer<typeof dealerCollectionRankSchema>
    type DealerGarmentTypesRankType = z.infer<typeof dealerGarmentTypesRankSchema>
  }
}
