import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleConflictError, handleError } from './api'
import { Shipment, validCouriers } from '../constants'
import { cleanObject } from '../helpers'
import { shipmentExtendedSchema, shipmentSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getShipment(id: number) {
  try {
    const response = await API.get(`shipments/${id}`)
    const shipment = shipmentExtendedSchema.parse(response.data)

    return shipment
  } catch (error) {
    return handleError(error, 'Shipment')
  }
}

export async function getShipments(rawParams: Record<string, unknown>) {
  try {
    const params = cleanObject(getShipmentsParamsSchema.parse(rawParams))
    const response = await API.get('shipments', { params })
    const shipments = z.array(shipmentSchema).parse(response.data)

    return { shipments, totalShipments: Number(response.headers.total) }
  } catch (error) {
    return handleError(error, 'Shipments')
  }
}

export async function completeShipment(rawParams: Record<string, unknown>) {
  try {
    const params = cleanObject(completeShipmentParamsSchema.parse(rawParams))
    await API.post(`shipments/${params.shipment_id}/complete`, { ...params })

    return {
      error: false,
      message: `Shipment #${params.shipment_id} has been moved to transit.`,
    }
  } catch (error) {
    return handleConflictError(error, 'Complete Shipment')
  }
}

export async function deleteShipment(id: number) {
  try {
    await API.delete(`shipments/${id}`)

    return {
      error: false,
      message: `Shipment #${id} has been deleted.`,
    }
  } catch (error) {
    return handleConflictError(error, 'Shipment')
  }
}

export async function removeFabricOrder(shipmentId: number, fabric_order_id: number) {
  try {
    await API.post(`shipments/${shipmentId}/remove_fabric_order`, { fabric_order_id })

    return {
      error: false,
      message: `Fabric Order #${fabric_order_id} has been removed and will be placed back in Pending status.`,
    }
  } catch (error) {
    return handleConflictError(error, 'Remove Fabric Order')
  }
}

//* ---------------- PARAMS ---------------- *//
const getShipmentsParamsSchema = z
  .object({
    addressId: z.coerce.number().optional(),
    trackingNumber: z.string().optional(),
    status: z.string().default(Shipment.Status.PENDING),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(25),
  })
  .transform(objectToSnake)

const completeShipmentParamsSchema = z
  .object({
    shipmentId: z.coerce.number(),
    trackingNumber: z.string().optional(),
    courier: z.enum(validCouriers),
    comments: z.string().optional(),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
