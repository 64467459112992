/** removed null and undefined values from object */
export const cleanObject = (obj: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v))

/** create object from formData */
export const parseFormData = async (request: Request) => Object.fromEntries(await request.formData())

/** create object from search params */
export const parseSearchParams = (request: Request) => Object.fromEntries(new URL(request.url).searchParams.entries())

/** get page and perPage from search params */
export const parsePageData = (request: Request, defaultPerPage: number = 100) => {
  const url = new URL(request.url)
  const page = url.searchParams.get('page') ?? 1
  const perPage = url.searchParams.get('perPage') ?? defaultPerPage
  return { page: Number(page), perPage: Number(perPage) }
}
