import { z } from 'zod'
import { API, handleError } from './api'
import { searchSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getSearches(params: { q: string }) {
  try {
    const response = await API.get('searches', { params })
    const searches = z.array(searchSchema).parse(response.data)

    return searches
  } catch (error) {
    return handleError(error, 'Searches')
  }
}

//* ---------------- PARAMS ---------------- *//

//* ---------------- HELPERS ---------------- *//
